import { createAction, props } from '@ngrx/store';
import { ProjectDescriptionUnit, ProjectInstance, UnitDropdown } from '../models/projects.models';
import { UnitProductModel } from '@cue/admin-units';

export const projectSelected = createAction(
  '[Projects] Project selected',
  props<{
    projectId: string;
  }>(),
);
export const loadProjectInstances = createAction(
  '[Projects] Load project instances',
  props<{
    projectId: string;
  }>(),
);
export const projectInstancesLoaded = createAction(
  '[Projects] Project instances loaded',
  props<{
    instances: ProjectInstance[];
  }>(),
);
export const setProjectInstances = createAction('[Projects] Project set new instances', props<{ instances: ProjectInstance[] }>());
export const addNewProjectInstance = createAction('[Projects] Project add new instance', props<{ instance: ProjectInstance }>());
export const deleteProjectInstance = createAction('[Projects] Project delete instance', props<{ instanceId: string }>());
export const applyNewInstanceTemplate = createAction(
  '[Projects] Apply new instance template',
  props<{ template: ProjectDescriptionUnit[]; unitProductModels: UnitProductModel[] }>(),
);
export const projectInstanceChanged = createAction(
  '[Projects] Project instance changed',
  props<{ value: { name: string; developerMode: boolean }; instanceId: string }>(),
);
export const projectInstanceUnitIdChanged = createAction(
  '[Projects] Project instance unit id changed',
  props<{ unitId: number; instanceId: string; instanceUnitId: string; units: UnitDropdown[] }>(),
);
export const projectInstanceUnitParameterChanged = createAction(
  '[Projects] Project instance unit parameter changed',
  props<{ value: string; instanceId: string; instanceUnitId: string; parameterName: string; useDefault: boolean }>(),
);
export const projectInstanceUnitObjectChanged = createAction(
  '[Projects] Project instance unit object changed',
  props<{ qrCodeIds: string[]; instanceId: string; instanceUnitId: string; objectName: string }>(),
);
export const setExpandedInstanceIds = createAction('[Projects] Project set expanded instance ids', props<{ ids: string[] }>());
export const addNewExpandedInstanceId = createAction('[Projects] Project add new expanded instance id', props<{ id: string }>());
