import { createReducer, on } from '@ngrx/store';
import { ProjectsRoot } from './projects-root';
import {
  addNewExpandedInstanceId,
  addNewProjectInstance,
  applyNewInstanceTemplate,
  deleteProjectInstance,
  projectInstanceChanged,
  projectInstancesLoaded,
  projectInstanceUnitIdChanged,
  projectInstanceUnitObjectChanged,
  projectInstanceUnitParameterChanged,
  projectSelected,
  setExpandedInstanceIds,
  setProjectInstances,
} from './projects.actions';

const initialState: ProjectsRoot = {
  projectId: undefined,
  instances: [],
  expandedInstanceIds: [],
};

const _stateReducer = createReducer(
  initialState,
  on(projectSelected, (state, { projectId }) => ({ ...state, projectId: projectId })),
  on(projectInstancesLoaded, (state, action) => ({
    ...state,
    instances: action.instances,
  })),
  on(setProjectInstances, (state, { instances }) => ({ ...state, instances: instances })),
  on(addNewProjectInstance, (state, { instance }) => ({ ...state, instances: [...state.instances, instance] })),
  on(deleteProjectInstance, (state, { instanceId }) => ({ ...state, instances: state.instances.filter((x) => x.id !== instanceId) })),
  on(applyNewInstanceTemplate, (state, { template, unitProductModels }) => {
    const projectInstances = [...state.instances];
    const instanceUnitNames = template.map((x) => x.instanceName);
    projectInstances.forEach((projectInstance) => {
      projectInstance.units = projectInstance.units.filter((x) => instanceUnitNames.includes(x.name));
      template.forEach((templateUnit) => {
        const projectInstanceUnit = projectInstance.units.find((x) => x.name === templateUnit.instanceName);
        if (projectInstanceUnit) {
          // parameters
          if (templateUnit.parameters) {
            if (projectInstanceUnit.parameters) {
              const parameterNames = templateUnit.parameters.map((x) => x.name);
              projectInstanceUnit.parameters = projectInstanceUnit.parameters.filter((x) => parameterNames.includes(x.name));
              templateUnit.parameters.forEach((templateUnitParameter) => {
                const projectInstanceUnitParameter = projectInstanceUnit.parameters.find((x) => x.name === templateUnitParameter.name);
                if (!projectInstanceUnitParameter) {
                  projectInstanceUnit.parameters.push({
                    id: crypto.randomUUID(),
                    name: templateUnitParameter.name,
                    objectName: templateUnitParameter.objectName,
                    propertyName: templateUnitParameter.propertyName,
                    dataType: templateUnitParameter.dataType,
                    defaultValue: templateUnitParameter.defaultValue,
                    propertyGroup: templateUnitParameter.propertyGroup,
                    editMode: templateUnitParameter.editMode,
                    value: null,
                    useDefault: false,
                  });
                } else {
                  projectInstanceUnitParameter.name = templateUnitParameter.name;
                  projectInstanceUnitParameter.objectName = templateUnitParameter.objectName;
                  projectInstanceUnitParameter.propertyName = templateUnitParameter.propertyName;
                  projectInstanceUnitParameter.dataType = templateUnitParameter.dataType;
                  projectInstanceUnitParameter.defaultValue = templateUnitParameter.defaultValue;
                  projectInstanceUnitParameter.propertyGroup = templateUnitParameter.propertyGroup;
                  projectInstanceUnitParameter.editMode = templateUnitParameter.editMode;
                }
              });
            } else {
              projectInstanceUnit.parameters = templateUnit.parameters?.map((parameter) => ({
                id: crypto.randomUUID(),
                name: parameter.name,
                objectName: parameter.objectName,
                propertyName: parameter.propertyName,
                dataType: parameter.dataType,
                defaultValue: parameter.defaultValue,
                propertyGroup: parameter.propertyGroup,
                editMode: parameter.editMode,
                value: null,
                useDefault: false,
              }));
            }
          } else {
            projectInstanceUnit.parameters = undefined;
          }
          // objects
          if (templateUnit.objects) {
            if (projectInstanceUnit.objects) {
              const objectNames = templateUnit.objects.map((x) => x.name);
              projectInstanceUnit.objects = projectInstanceUnit.objects.filter((x) => objectNames.includes(x.name));
              templateUnit.objects.forEach((templateUnitObject) => {
                const projectInstanceUnitObject = projectInstanceUnit.objects.find((x) => x.name === templateUnitObject.name);
                if (!projectInstanceUnitObject) {
                  projectInstanceUnit.objects.push({
                    id: crypto.randomUUID(),
                    name: templateUnitObject.name,
                    type: templateUnitObject.type,
                    qrCodeIds: [],
                  });
                } else {
                  projectInstanceUnitObject.name = templateUnitObject.name;
                  projectInstanceUnitObject.type = templateUnitObject.type;
                }
              });
            } else {
              projectInstanceUnit.objects = templateUnit.objects?.map((object) => ({
                id: crypto.randomUUID(),
                name: object.name,
                type: object.type,
                qrCodeIds: [],
              }));
            }
          } else {
            projectInstanceUnit.objects = undefined;
          }
        } else {
          const productModel = unitProductModels.find((x) => x.csCode === templateUnit.csCode);
          projectInstance.units.push({
            name: templateUnit.instanceName,
            id: crypto.randomUUID(),
            unitId: undefined,
            productModelId: productModel.id,
            productCode: productModel.code,
            productModelName: productModel.name,
            status: 'unassigned',
            parameters: templateUnit.parameters?.map((parameter) => ({
              id: crypto.randomUUID(),
              name: parameter.name,
              objectName: parameter.objectName,
              propertyName: parameter.propertyName,
              dataType: parameter.dataType,
              defaultValue: parameter.defaultValue,
              propertyGroup: parameter.propertyGroup,
              editMode: parameter.editMode,
              value: null,
              useDefault: false,
            })),
            objects: templateUnit.objects?.map((object) => ({
              id: crypto.randomUUID(),
              name: object.name,
              type: object.type,
              qrCodeIds: [],
            })),
          });
        }
      });
    });
    return { ...state, instances: [...projectInstances] };
  }),
  on(projectInstanceChanged, (state, { value, instanceId }) => {
    const instance = state.instances.find((x) => x.id === instanceId);
    if (instance) {
      instance.name = value.name ?? instance.name;
      instance.developerMode = value.developerMode ?? instance.developerMode;
      return { ...state, instances: [...state.instances] };
    }
    return { ...state };
  }),
  on(projectInstanceUnitIdChanged, (state, { unitId, instanceId, instanceUnitId, units }) => {
    const unit = units.find((x) => x.id === unitId);
    const oldInstances = [...state.instances];
    const instance = oldInstances.find((x) => x.id === instanceId);
    if (instance && instance.units && instance.units.length) {
      const instanceUnit = instance.units.find((x) => x.id === instanceUnitId);
      if (instanceUnit) {
        instanceUnit.unitId = unitId;
        instanceUnit.status = unit ? (!unit.unregistered ? (unit.online ? 'online' : 'offline') : 'unregistered') : 'unassigned';

        instance.status = instance.units.some((x) => x.status === 'unassigned')
          ? 'unassigned'
          : instance.units.some((x) => x.status === 'unregistered')
            ? 'unregistered'
            : instance.units.some((x) => x.status === 'offline')
              ? 'offline'
              : 'online';
        return { ...state, instances: [...state.instances] };
      }
    }
    return { ...state };
  }),
  on(projectInstanceUnitParameterChanged, (state, { value, instanceId, instanceUnitId, parameterName, useDefault }) => {
    const newState = { ...state };
    const instance = newState.instances.find((x) => x.id === instanceId);
    if (instance && instance.units && instance.units.length) {
      const instanceUnit = instance.units.find((x) => x.id === instanceUnitId);
      if (instanceUnit && instanceUnit.parameters && instanceUnit.parameters.length) {
        const parameter = instanceUnit.parameters.find((x) => x.name === parameterName);
        if (parameter) {
          if (value !== null) parameter.value = value;
          if (useDefault !== null) {
            parameter.useDefault = useDefault;
            if (useDefault) parameter.value = null;
          }
          return { ...state, instances: [...newState.instances] };
        }
      }
    }
    return { ...state };
  }),
  on(projectInstanceUnitObjectChanged, (state, { qrCodeIds, instanceId, instanceUnitId, objectName }) => {
    const newState = { ...state };
    const instance = newState.instances.find((x) => x.id === instanceId);
    if (instance && instance.units && instance.units.length) {
      const instanceUnit = instance.units.find((x) => x.id === instanceUnitId);
      if (instanceUnit && instanceUnit.objects && instanceUnit.objects.length) {
        const object = instanceUnit.objects.find((x) => x.name === objectName);
        if (object) {
          object.qrCodeIds = qrCodeIds;
          return { ...state, instances: [...newState.instances] };
        }
      }
    }
    return { ...state };
  }),
  on(setExpandedInstanceIds, (state, { ids }) => ({ ...state, expandedInstanceIds: ids })),
  on(addNewExpandedInstanceId, (state, { id }) => ({ ...state, expandedInstanceIds: [...state.expandedInstanceIds, id] })),
);

export function projectsReducer(state: any, action: any) {
  return _stateReducer(state, action);
}
